<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <div class="headerContainer">
        <img :src="logo" class="logo" />
      </div>
      <div class="mainContainer">
        <div class="subContainer">
          <h1 class="title">Regístrate ahora</h1>
          <div class="inputRow">
            <custom-input
              type="text"
              :modelValue="name"
              :modelInvalid="invalidName"
              @update:modelValue="name = $event"
              @update:modelInvalid="invalidName = $event"
              placeholder="* Nombre"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="email"
              :modelValue="email"
              :modelInvalid="invalidEmail"
              @update:modelValue="email = $event"
              @update:modelInvalid="invalidEmail = $event"
              placeholder="* E-mail"
            />
          </div>
          <div class="inputRow">
            <custom-date-picker-input
              v-model="birthday"
              :modelInvalid="invalidBirthday"
              @update:modelInvalid="invalidBirthday = $event"
              @save="$emit('save')"
              @click.stop=""
            />
            <drop-down
              v-model="gender"
              input-class="text-center"
              :modelInvalid="invalidGender"
              :modelValue="gender"
              @update:modelValue="gender = $event"
              @update:modelInvalid="invalidGender = $event"
              :options="genders"
              class-name="bg-white-3 px-2 rounded-md"
              label_by="label"
              value_by="value"
              placeholder="* Género"
              :className="`wp-48 ${invalidGender ? 'invalid' : 'valid'}`"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="password"
              :modelInvalid="invalidPassword"
              :modelValue="password"
              @update:modelValue="password = $event"
              @update:modelInvalid="invalidPassword = $event"
              placeholder="* Contraseña"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="password"
              :modelValue="confirmPassword"
              :modelInvalid="invalidConfirmPasswrd"
              @update:modelValue="confirmPassword = $event"
              @update:modelInvalid="invalidConfirmPasswrd = $event"
              placeholder="* Repetir contraseña"
            />
          </div>
          <div class="termsRow">
            <input
              type="checkbox"
              :class="`checkbox ${acceptError ? 'checkError' : ''}`"
              v-model="isAccepted"
            />
            <p class="termsDes">
              Acepto las
              <a
                class="termsBtn no-border"
                href="http://besukha.com/terminos/"
                target="blank"
                >condiciones y términos de uso.</a
              >
            </p>
          </div>
          <button
            class="authBtnContainer bg-yellow mb-20"
            @click="registerAttempt()"
          >
            ENVIAR
          </button>
          <button
            class="socialAuthBtnContainer bg-blue"
            @click="loginWithFacebookAttempt()"
          >
            <span class="socialIcon">
              <font-awesome-icon
                :icon="{ prefix: 'fab', iconName: 'facebook' }"
              />
            </span>
            {{ $t("labels.loginWithFacebook") }}
          </button>
          <button
            class="socialAuthBtnContainer bg-pink"
            @click="loginWithGoogleAttempt()"
          >
            <span class="socialIcon">
              <font-awesome-icon
                :icon="{ prefix: 'fab', iconName: 'google' }"
              />
            </span>
            {{ $t("labels.loginWithGoogle") }}
          </button>
          <div class="termsRow">
            <p class="termsDes">
              Tienes ya una cuenta?
              <span class="termsBtn no-border" @click="goToLogin()"
                >Identifícate</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "../components/CustomInput.vue";
import CustomDatePickerInput from "../components/CustomDatePickerInput.vue";
import DropDown from "../components/DropDown.vue";
let self;
export default {
  name: "RegisterPage",
  components: {
    CustomInput,
    CustomDatePickerInput,
    DropDown,
  },
  data() {
    return {
      logo: require("../assets/image/svg/logo.svg"),
      name: "",
      invalidName: false,
      email: "",
      invalidEmail: false,
      birthday: "",
      invalidBirthday: false,
      gender: "",
      invalidGender: false,
      isAccepted: false,
      acceptError: false,
      genders: [
        {
          value: "1",
          label: "Chico",
        },
        {
          value: "2",
          label: "Chica",
        },
        {
          value: "3",
          label: "Otro",
        },
      ],
      password: "",
      invalidPassword: false,
      confirmPassword: "",
      invalidConfirmPasswrd: false,
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToDashboard() {
      this.$router.push("/dashboard");
    },

    registerAttempt() {
      let flag = true;
      if (!this.name) {
        flag = false;
        this.invalidName = true;
      }
      if (!this.email) {
        flag = false;
        this.invalidEmail = true;
      }
      if (!this.birthday) {
        flag = false;
        this.invalidBirthday = true;
      }
      if (!this.gender) {
        flag = false;
        this.invalidGender = true;
      }
      if (!this.password) {
        flag = false;
        this.invalidPassword = true;
      }
      if (!this.confirmPassword) {
        flag = false;
        this.invalidConfirmPasswrd = true;
      }

      if (!this.isAccepted) {
        this.acceptError = true;
        flag = false;
      }
      if (!flag) return;
      if (this.password != this.confirmPassword) {
        this.invalidConfirmPasswrd = true;
        return;
      }

      let data = {
        name: this.name,
        email: this.email,
        birthday: this.birthday,
        gender: this.gender,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };

      this.$store.dispatch("auth/registerAttempt", data);
    },
    async loginWithFacebookAttempt() {
      self = this;
      if (window.FB) {
        window.FB.login(function (response) {
          if (response.authResponse) {
            // alert("You are logged in &amp; cookie set!");
            console.log("fblogin: ", response.authResponse);
            window.FB.api(
              "/me",
              "GET",
              {
                access_token: response.authResponse.accessToken,
                fields: "id,name,gender,email,picture",
              },
              function (res) {
                console.log("facebook user info: ", res);
                let data = {
                  email: res.email,
                  auth_type: "facebook",
                  auth_token: response.authResponse.accessToken,
                };

                self.$store.dispatch("auth/loginAttempt", data);
              }
            );
            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.
          } else {
            // alert("User cancelled login or did not fully authorize.");
          }
        });
      }
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "1002345903572729", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v10.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    async loginWithGoogleAttempt() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        // console.log("googleUser", googleUser);
        this.user = googleUser.getBasicProfile().getEmail();
        // console.log("getId", this.user);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse());
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );
        let data = {
          email: googleUser.getBasicProfile().getEmail(),
          auth_type: "google",
          auth_token: googleUser.getAuthResponse().access_token,
        };
        console.log("data: ", data);
        this.$store.dispatch("auth/loginAttempt", data);
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },

    async handleClickGetAuthCode() {
      try {
        const authCode = await this.$gAuth.getAuthCode();
        console.log("authCode", authCode);
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
  },
  beforeMount: async function() {
    await this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  },
};
</script>

<style scoped>
.termsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.termsDes {
  font-size: 13px;
  color: var(--textColor);
}
.termsBtn {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.no-border {
  border: none;
}

.acceptError {
}
</style>
